table {
  width: 100%;
  th {
    height: 60px;
    font-size: 12px !important;
    color: var(--color-primary-background) !important;
    font-family: Roboto-Regular !important;
    background-color: var(--gray-background) !important;
    border: none;
  }
}

mat-calendar {
  th {
    height: auto;
    color: var(--color-darkest) !important;
    background-color: var(--color-primary-background) !important;
  }
}

.mat-mdc-row:nth-child(even) {
  background-color: var(--color-lightest);
}

.mat-mdc-row:nth-child(odd) {
  background-color: var(--color-primary-light);
}

th.mat-mdc-header-cell {
  font-size: 12px !important;
  color: var(--color-primary-background) !important;
  font-family: Roboto-Regular !important;
  background-color: var(--gray-background);
  border: none;
}

.account-number {
  cursor: pointer;
  color: var(--primary-A550) !important;
}

/** Table status styles **/

.active {
  color: var(--success-600);
  text-align: justify;
}

.pending {
  color: var(--accent-600);
  text-align: justify;
}

.disabled {
  color: var(--warn-600);
  text-align: justify;
}

.mat-table-icon-btn {
  color: var(--gray-background);
}
button:disabled > .mat-table-icon-btn {
  color: var(--table-btn-disabled);
}

@media (max-width: 1024px) {
  /********** Table **********/
  .mat-mdc-table thead,
  .mat-mdc-table tbody {
    display: block;
    width: 100%;
  }
  tr.mat-mdc-row {
    margin: 8px 0px;
    border-bottom: 1px solid var(--light-border);
  }
  tr.mat-mdc-row,
  tr.mat-mdc-header-row,
  tr.mat-mdc-footer-row {
    display: flex;
    flex-flow: column wrap;
    box-sizing: border-box;
    overflow: hidden;
    height: auto !important;
  }
  tr.mat-mdc-header-row {
    height: auto !important;
    border-bottom: 1px solid var(--light-border);
  }
  th.mat-mdc-header-cell {
    height: auto !important;
  }
  tr.mat-mdc-footer-row {
    border-top: 1px solid var(--light-border);
  }
  td.mat-mdc-cell,
  th.mat-mdc-header-cell,
  td.mat-mdc-footer-cell {
    display: block;
    width: 100%;
    box-sizing: border-box;
    padding: 3px 10px !important;
    border: 0 !important;
    margin-bottom: 0px;
  }
  .mat-mdc-column-actions.three-icons {
    width: 100%;
  }
  th .mat-mdc-form-field-wrapper {
    padding-bottom: 0;
  }
  th .mat-mdc-form-field-appearance-standard .mat-mdc-form-field-flex {
    padding-top: 0;
  }
  th .mat-mdc-form-field-infix {
    border-top: 0;
  }
  th .mat-mdc-form-field-underline {
    bottom: 0;
  }
  .mat-mdc-paginator-container {
    padding: 0 !important;
  }
  .mat-mdc-paginator-range-actions,
  .mat-mdc-paginator-page-size {
    margin: 0 auto !important;
  }
  .mat-mdc-dialog-title {
    scale: 0.9;
  }
}

/** Table Component **/
.table-header-container {
  background: var(--light-background);
  border: 1px solid var(--light-border);
  display: flex;
  flex-wrap: wrap;
  @apply sm:justify-start sm:items-center xl:justify-between xl:items-center gap-2 shadow-md py-5 px-8 rounded-md my-5;
}

.actions {
  display: flex;
  flex-wrap: wrap;
  @apply content-center sm:justify-end sm:items-center xl:justify-between xl:items-center gap-5;
}

.action-icons {
  display: flex;
  @apply content-start sm:items-center xl:items-center gap-1 text-gray-background;
}

.mat-mdc-table {
  border-color: var(--light-background);
}

tr.mat-mdc-row,
tr.mat-mdc-footer-row {
  height: 40px;
}

tr.mat-mdc-header-row {
  height: 60px;
  background: var(--light-border);
}

tr.columns-label {
  background-color: var(--color-darker);
  color: var(--color-primary-background);
}

.hideFilter {
  display: none;
}

.showFilters {
  display: block;
}

.mat-mdc-refresh {
  color: var(--color-darker) !important;
  background-color: var(--light-border);
}

.mat-table-container {
  width: 100%;
  white-space: nowrap;
}

.mat-mdc-no-data-row {
  height: 40px !important;
}

// ********** Paginator **********

.mat-mdc-paginator-outer-container {
  border-top: solid 1px var(--light-border);

  .mdc-notched-outline__trailing,
  .mdc-notched-outline__notch,
  .mdc-notched-outline__leading {
    border: none !important;
    border-bottom: solid 1px var(--toolbar-shadow) !important;
    border-radius: 0 !important;
  }
}

.mat-mdc-paginator-page-size {
  align-items: center !important;
}

.mat-mdc-paginator-page-size-label,
.mat-mdc-paginator-range-label {
  color: var(--dark-disabled-text) !important;
  font-size: 12px !important;
}

.mat-mdc-paginator-page-size-select {
  .mdc-text-field--outlined .mat-mdc-form-field-infix,
  .mdc-text-field--no-label .mat-mdc-form-field-infix {
    min-height: fit-content !important;
    padding: 0 !important;
  }

  .mat-mdc-text-field-wrapper {
    width: 70% !important;

    &.mdc-text-field {
      padding: 0 !important;
    }
  }
}

// @use 'assets/scss/fonts';

body {
  font-family: MepcoText !important;
  overflow: overlay;
}

// Font icon class
.fab {
  font-family: 'Font Awesome 5 Brands', 'fa-brands-400';
  font-weight: 400;
}

.fas {
  font-family: 'Font Awesome 5 Free', 'fa-solid-900';
  font-weight: 900;
}

.far {
  font-family: 'Font Awesome 5 Free', 'fa-regular-400';
  font-weight: 400;
}

app-duedate {
  .mat-form-field-infix {
    padding: 0 !important;
    border-top: 0em solid transparent !important;
  }
  .mat-form-field-appearance-standard .mat-form-field-flex {
    padding-top: 0 !important;
  }
}

app-contract-summary {
  .mat-tab-label {
    height: 40px !important;
    padding: 2px !important;
    font-family: Roboto-Regular !important;
  }
}

@font-face {
  font-family: MepcoText !important;
  src: url('../fonts/M025096D.ttf') !important;
}

@font-face {
  font-family: MicrogrammaD-BoldExte;
  src: url('../fonts/M025096D.ttf') format('truetype');
}

@font-face {
  font-family: PTSans-Narrow;
  src: url('../fonts/PTSansNarrow-Regular.ttf') format('truetype');
}

@font-face {
  font-family: PTSans-NarrowBold;
  src: url('../fonts/PTSansNarrow-Bold.ttf') format('truetype');
}

@font-face {
  font-family: Roboto-Thin;
  src: url('../fonts/Roboto-Thin.ttf') format('truetype');
}

@font-face {
  font-family: Roboto-Bold;
  src: url('../fonts/Roboto-Bold.ttf') format('truetype');
}

@font-face {
  font-family: Roboto-Light;
  src: url('../fonts/Roboto-Light.ttf') format('truetype');
}

@font-face {
  font-family: Roboto-Medium;
  src: url('../fonts/Roboto-Medium.ttf') format('truetype');
}

@font-face {
  font-family: Roboto-Regular;
  src: url('../fonts/Roboto-Regular.ttf') format('truetype');
}

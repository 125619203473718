// These colors are the same for indigo and pink palette defined for pre-build themes that Angular Material provides
// https://github.com/angular/components/blob/main/src/material/core/m2/_palette.scss

// In case it is needed a  different  palette changes the values
// Moreover, these colors are defined in tailwindcss.config so they can be accessed as css classes
:root {
  /* Light text and UI colors */
  --light-primary-text: rgba(255, 255, 255, 1); /* white #FFFFFF */
  --light-secondary-text: rgba(255, 255, 255, 0.7); /* #B3B3B3 */
  --light-disabled-text: rgba(255, 255, 255, 0.5); /* #7F7F7F */
  --light-dividers: rgba(255, 255, 255, 0.12); /* #1F1F1F */
  --light-focused: rgba(255, 255, 255, 0.12); /* #1F1F1F */
  --light-background: rgba(250, 250, 250, 1); /* #FAFAFA */
  --light-border: rgba(232, 232, 237, 1); /* #E8E8ED */

  /* Dark text and UI colors */
  --dark-primary-text: rgba(0, 0, 0, 0.87); /* #0D0D0D */
  --dark-secondary-text: rgba(0, 0, 0, 0.54); /* #8A8A8A */
  --dark-disabled-text: rgba(0, 0, 0, 0.38); /* #616161 */
  --dark-dividers: rgba(0, 0, 0, 0.12); /* #1F1F1F */
  --dark-focused: rgba(0, 0, 0, 0.12); /* #1F1F1F */
  --background-toolbar: #16191f; /* #16191F */
  --toolbar-shadow: rgba(107, 107, 107, 0.72); /* #6B6B6B */

  --home-title-text: #1b1b1b; /* #1B1B1B */
  --home-subtitle-text: #2b2b2b; /* #2B2B2B */

  /* Gray background */
  --gray-background: rgba(84, 91, 100, 1); /* #545B64 */

  /* Neutral colors from lightest to darkest */
  --color-primary-background: rgba(255, 255, 255, 1); /* #FFFFFF */
  --color-primary-light: rgba(252, 252, 253, 1); /* #FCFCFD */
  --color-lightest: rgba(248, 249, 250, 1); /* #F8F9FA */
  --color-lightest-middle: rgba(240, 243, 245, 1); /* #F0F3F5 */
  --color-lighter: rgba(233, 236, 239, 1); /* #E9ECEF */
  --color-lighter-middle: rgba(228, 231, 234, 1); /* #E4E7EA */
  --color-light: rgba(222, 226, 230, 1); /* #DEE2E6 */
  --color-neutral-light: rgba(206, 212, 218, 1); /* #CED4DA */
  --color-neutral: rgba(173, 181, 189, 1); /* #ADB5BD */
  --color-neutral-dark: rgba(141, 149, 157, 1); /* #8D959D */
  --color-dark: rgba(108, 117, 125, 1); /* #6C757D */
  --color-darker: rgba(73, 80, 87, 1); /* #495057 */
  --color-darkest: rgba(52, 58, 64, 1); /* #343A40 */
  --color-black-light: rgba(33, 37, 41, 1); /* #212529 */
  --color-black: rgba(0, 0, 0, 1); /* var(--color-black) */

  --success-50: #e8f5e9;
  --success-100: #c8e6c9;
  --success-200: #a5d6a7;
  --success-300: #81c784;
  --success-400: #66bb6a;
  --success-500: #4caf50;
  --success-600: #43a047;
  --success-700: #388e3c;
  --success-800: #2e7d32;
  --success-900: #1b5e20;
  --success-950: #104b17;
  --success-1000: #08380f;
  --success-A100: #b9f6ca;
  --success-A200: #69f0ae;
  --success-A400: #00e676;
  --success-A700: #00c853;

  --primary-50: #e8eaf6;
  --primary-100: #c5cae9;
  --primary-200: #9fa8da;
  --primary-300: #7986cb;
  --primary-400: #5c6bc0;
  --primary-500: #3f51b5;
  --primary-600: #3949ab;
  --primary-700: #303f9f;
  --primary-800: #283593;
  --primary-900: #1a237e;
  --primary-950: #121865;
  --primary-1000: #0b104d;
  --primary-A100: #8c9eff;
  --primary-A200: #536dfe;
  --primary-A300: #2e72d6;
  --primary-A400: #3d5afe;
  --primary-A500: #304ffe;
  --primary-A550: #0073bb;
  --primary-A600: #035397;
  --primary-A700: #0f4673;
  --primary-A800: #1c3a4f;
  --primary-A900: #232f3d;

  --accent-50: #fef1e7;
  --accent-75: rgb(255, 245, 228, 0.8);
  --accent-100: #fbdcc2;
  --accent-200: #f9c599;
  --accent-300: #f7ae70;
  --accent-400: #f59c52;
  --accent-500: #f38b33;
  --accent-600: #f1832e;
  --accent-700: #ef7827;
  --accent-800: #ed6e20;
  --accent-900: #ea5b14;
  --accent-950: #c04a0f;
  --accent-1000: #97370a;

  --accent-A100: #ffffff;
  --accent-A200: #ffeee6;
  --accent-A400: #ffcab3;
  --accent-A700: #ffb89a;

  --warn-50: #ffebee;
  --warn-100: #ffcdd2;
  --warn-200: #ef9a9a;
  --warn-300: #e57373;
  --warn-400: #ef5350;
  --warn-500: #f44336;
  --warn-600: #e53935;
  --warn-700: #d32f2f;
  --warn-800: #c62828;
  --warn-900: #b71c1c;
  --warn-950: #8f1414;
  --warn-1000: #670e0e;
  --warn-A100: #ff8a80;
  --warn-A200: #ff5252;
  --warn-A400: #ff1744;
  --warn-A700: #d50000;

  /** Gray colors **/
  --gray-50: #f7f7f7;
  --gray-100: #f1f1f1;
  --gray-200: #ececec;
  --gray-300: #c7cbce;
  --gray-400: #a0a5ac;
  --gray-500: #7a8087;
  --gray-600: #545b64;
  --gray-700: #515660;
  --gray-800: #444a51;
  --gray-900: #373b42;
  --gray-1000: #2a2d32;

  /** Snackbar colors **/
  --snackbar-success-background: #d9f8c4;
  --snackbar-error-background: #fbc5c5;
  --snackbar-info-background: #2f4462;
  --snackbar-warning-background: #fad9a1;

  --snackbar-success-text: #4b5d67;
  --snackbar-error-text: #990000;
  --snackbar-info-text: var(--color-primary-background);
  --snackbar-warning-text: #9e5600;

  /** Table button icons **/
  --table-btn-disabled: #9c9c9d;
}

/* You can add global styles to this file, and also import other style files */

html,
body {
  margin: 0;
  padding: 0;
  color: var(--color-darkest);
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
  height: 100%;
  background: var(--light-primary-text);
  display: block;
}

.toolbar {
  background: var(--background-toolbar) !important;
  -webkit-box-shadow: 0px 1px 2px 2px var(--toolbar-shadow);
  box-shadow: 0px 1px 2px 2px var(--toolbar-shadow);
  height: 64px;
  color: var(--light-primary-text);
  position: sticky;
  padding: 0px 24px;
  top: 0;
  z-index: 999;
}

.toolbar > .progress-bar-container {
  height: auto;
  padding: 0;
  background-color: var(--light-border);
}

.toolbar > .content-container {
  padding: 0;
}

.toolbar > .content-container > .sidenav-container,
.toolbar > .content-container > .sidenav-container > button {
  height: 100%;
}

.toolbar > .content-container > .sidenav-container {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}

.toolbar > .content-container > .logo-container {
  overflow: hidden;
  height: 100%;
}

.toolbar > .content-container > .logo-container img {
  z-index: 2;
  width: auto;
  height: 55px;
  display: block;
  position: relative;
  padding-left: 25px;
  image-rendering: auto;
  transition: opacity 0.4s ease-in-out;
  -moz-transition: opacity 0.4s ease-in-out;
  -webkit-transition: opacity 0.4s ease-in-out;
}

.toolbar > .content-container > .action-container {
  height: 100%;
}

.toolbar > .content-container > .action-container > .user-container {
  font-size: 14px;
  padding: 0 16px;
}

.toolbar > .content-container > .action-container > .user-container .icon {
  margin-right: 5px;
}

.toolbar > .content-container > .action-container > .logoff-container {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}

.mat-drawer-content {
  position: relative;
  z-index: 1;
  display: block;
  height: 600px;
  overflow: auto;
  background: var(--color-primary-background) !important;
  background-color: var(--color-primary-background) !important;
  min-height: 600px !important;
}

a {
  text-decoration: none;
}

md-primary.formPage:not([disabled]) .flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-grow {
  flex-grow: 1;
  flex-shrink: 0;
}

.no-margin {
  margin: 0;
}

.no-padding {
  padding: 0;
}

.full-width {
  width: 100%;
}

/****** Kill Switch ******/

.cancelled-message-container {
  border-radius: 5px;
  padding: 16px 48px;
  margin: 24px 16px;
  background-color: var(--accent-500);
  position: relative;
  .label {
    font-family: Roboto-Light;
    font-size: 15px;
    color: var(--light-primary-text);
    margin: 8px;
  }
  .icon-info {
    color: var(--light-primary-text);
    margin-right: 8px;
  }
}

.active-schedule-future-paymeny-message-container {
  border-radius: 5px;
  padding: 16px 48px;
  margin: 16px 0px;
  background-color: var(--accent-500);
  position: relative;
  .label {
    font-family: Roboto-Light;
    font-size: 15px;
    color: var(--light-primary-text);
    margin: 8px;
  }
  .icon-info {
    color: var(--light-primary-text);
    margin-right: 8px;
  }
}

.kill-switch-container {
  border-radius: 5px;
  padding: 8px 48px;
  margin: 24px 16px;
  background-color: var(--color-darker);
  position: relative;
  .label {
    margin: 8px;
    font-family: Roboto-Light;
    font-size: 15px;
    color: var(--light-primary-text);
    .icon-info {
      color: var(--light-primary-text);
      margin-right: 8px;
    }
  }
  .icon-info {
    color: var(--light-primary-text);
    margin-right: 8px;
  }
}

/*********** Dimmer ***********/

.dimmer {
  display: none;
  position: fixed;
  top: 4px;
  left: 0;
  width: 100%;
  height: calc(100% - 4px);
  text-align: center;
  vertical-align: middle;
  background-color: rgba(0, 0, 0, 0.7);
  line-height: 1;
  z-index: 10000;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  will-change: opacity;
}

.dimmer.active {
  display: flex;
  opacity: 1;
}

.dimmer.disabled {
  display: none;
  width: 0;
  height: 0;
}

/*********** Snackbar ***********/

.view-content-root-container {
  position: fixed;
  top: 105px;
  bottom: 59px;
  left: 0;
  right: 0;
  overflow-y: auto;
  background: var(--light-primary-text);
}

.view-content-container > .header {
  color: var(--primary-A700);
  background-color: var(--color-primary-light);
  width: 100%;
  height: 86px;
  margin: 0;
  padding: 0;
  min-height: 75px;
  font-size: 25px;
  font-weight: 600;
  border-radius: 4px;
}

.view-content-container > .header .icon {
  font-size: 55px;
  width: 55px;
  height: 55px;
  margin-right: 15px;
}

.view-content-container > .header .title {
  padding-top: 9px;
}

.view-content-container > .header .sub-title {
  font-size: 16px;
}

.red {
  color: var(--warn-700);
}

.green {
  color: var(--success-700);
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.display-none {
  display: none;
}

/*********** Table ***********/

.mat-mdc-table .mat-mdc-column-actions {
  width: 80px;
  padding-right: 24px !important;
  text-align: center;
}

.mat-mdc-table .column-header h3 {
  margin: 0;
}

.mat-mdc-table .column-filter:first-of-type {
  padding: 0px 10px 0 24px !important;
}

.mat-mdc-table .column-filter:not(:first-of-type) .column-filter:not(:last-of-type) {
  padding: 0 10px 0 0 !important;
}

.mat-mdc-table .column-filter .mat-mdc-form-field > .mat-mdc-form-field-wrapper {
  padding-bottom: 0;
}

.mat-mdc-table .column-filter .mat-mdc-form-field .mat-mdc-form-field-infix {
  width: auto;
  min-width: 75px;
  border-top: 0;
}

.mat-mdc-table .column-filter .mat-mdc-form-field .mat-mdc-form-field-flex {
  padding-top: 0;
}

.mat-mdc-table .column-filter .mat-mdc-form-field .mat-mdc-form-field-underline {
  bottom: 0;
}

.mat-mdc-table .column-truncate-text-small {
  max-width: 30px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.mat-mdc-table .column-truncate-text-extra-large {
  max-width: 30vw;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.mat-mdc-table .column-truncate-text {
  max-width: 50px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.mat-mdc-table .column-truncate-text-large {
  max-width: 120px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.mat-mdc-table .mat-mdc-cell,
.mat-mdc-footer-cell {
  font-size: 13px !important;
}

th.mat-mdc-header-cell,
td.mat-mdc-cell,
td.mat-mdc-footer-cell {
  padding-right: 10px !important;
}

/*
  TODO: IMPROVE CSS ORDER AND CREATE NEW CLASSES AND FILES
*/

.mail {
  color: var(--primary-A300);
}

.highlight-info {
  font-family: Roboto-Medium;
}

.table-font-family {
  font-family: Roboto-Regular;
}

/** password-strength styles**/

mat-password-strength-info > mat-card {
  box-shadow: none !important;
  background: none !important;
  place-content: start !important;
}

/***** Reports Styles ****/
.report {
  margin-top: 0px;
}
.report.mat-card-title {
  font-size: 20px !important;
  font-family: Roboto-Regular !important;
  margin-bottom: 8px !important;
  color: var(--color-black-light) !important;
}
